
import {defineComponent,reactive,toRefs,ref,watch } from "vue";
import {addImei,editImei,createDevImeis,isEditAuthByCode} from "@/api/index";
import { useStore } from 'vuex';
import { ElMessage, ElLoading } from 'element-plus';
import { ArrowDown } from '@element-plus/icons-vue'
interface deviceDataF{
    addImeiFlag:boolean,
    editImeiForm:any,
    
    yearVal:number,
    // statusVal:string,
    // dateValue:string,
    // customerName:string
    searchDataAny:any,//搜索条件
    
    importImeiForm:any,
    importImeiFlag:boolean,
    imeiTableType:any,
    token:any,
    uploadLoading:any,
    statusOption:any,
    expireDayOption:any,imeiTypeOption:any,rules:any,
    importImeiToTXTFlag:boolean,
    importImeiToTXTForm:any,
    createDevImeisFlag:boolean,
    createDevImeisForm:any,
    editSign:number // 0:没有标志 1:修改 2:增加
}

export default defineComponent({
    name:'ImeiSeach',
    components: {
        ArrowDown
    },
    props:{
        statusOption:Array,
        editImeiFormData:{},
        editImeiFlag:Boolean,
        imeiTableType:Number
    },
    emits:['searchData','addImeiSuccuss','deleteImeiAll','removeReg','editImeiClosed'],
    setup(props,{emit}){
        
        const imeiRef = ref();
        const uploadRef = ref();
        const uploadTXTRef = ref();
        const store = useStore();
        const deviceData:deviceDataF = reactive({
            //customerName:'',
            //dateValue: '',
            //statusVal:'',  //全部：'' 0:未绑定 1:车联网已绑定 2:手表已绑定 3:宠物已绑定  status


            rules:{
                type: [
                    {required: true,message: '请输选择设备类型',trigger: 'blur'},
                ],
                expireDay:[
                    {required: true,message: '请输入授权天数',trigger: 'blur'},
                ],
                imeis:[
                    {required: true,message: '请输入IMEI号',trigger: 'blur'},
                ],
                imeiStart:[
                    {required: true,message: '请输入IMEI前8位',trigger: 'blur'},
                ],
                begin:[
                    {required: true,message: '请输入IMEI起始值',trigger: 'blur'},
                ],
                end:[
                    {required: true,message: '请输入IMEI结束值',trigger: 'blur'},
                ]
            },

            statusOption:[
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: 0,
                    label: '未绑定',
                },
                {
                    value: 1,
                    label: '已绑定',
                }
            ],//JSON.parse(JSON.stringify(props.statusOption)),
            
            expireDayOption:[
                {
                    value: 30,
                    label: '30',
                },
                {
                    value: 60,
                    label: '60',
                },
                {
                    value: 90,
                    label: '90',
                },
                {
                    value: 120,
                    label: '120',
                },
                {
                    value: 150,
                    label: '150',
                },
                {
                    value: 180,
                    label: '半年',
                },
                {
                    value: 365,
                    label: '1年',
                },
                {
                    value: 730,
                    label: '2年',
                },
                {
                    value: 1095,
                    label: '3年',
                },
                {
                    value: 1460,
                    label: '4年',
                },
                {
                    value: 1825,
                    label: '5年',
                },
                {
                    value: 2190,
                    label: '6年',
                },
                {
                    value: 2555,
                    label: '7年',
                },
                {
                    value: 2920,
                    label: '8年',
                },
                {
                    value: 3285,
                    label: '9年',
                },
                {
                    value: 3650,
                    label: '10年',
                },
            ],
            //（2：手表 ；1：车联网3：宠物）
            imeiTypeOption:[
                // {
                //     value: '',
                //     label: '请选择',
                // },
                {
                    value: 1,
                    label: '车联网',
                },
                {
                    value: 2,
                    label: '手表',
                },
                {
                    value: 3,
                    label: '宠物',
                },
            ],
            yearVal:30,
            //expireDay:'30',
            //imeiVal:'',
            //cusName:'',
            //imeiType:1
            searchDataAny:{},//搜索条件

            addImeiFlag:false,
            editImeiForm:{expireDay:30},
            
            importImeiFlag:false,
            importImeiForm:{},
            importImeiToTXTFlag:false,
            importImeiToTXTForm:{expireDay:30},
            imeiTableType:props.imeiTableType,
            createDevImeisFlag:false,
            createDevImeisForm:{},
            token:{Authorization: sessionStorage.getItem('token')},
            editSign:0,
            uploadLoading:null,
        });
        const data = toRefs(deviceData);

        const editImeiFun = ()=>{
            let obj:any = {};
            Object.assign(obj, props.editImeiFormData);
            
            if(obj.imeis==null || obj.imeis.length==0){
                
                ElMessage.error("请选择需要修改的数据");
                return;
            }
            deviceData.editSign = 1;
            deviceData.addImeiFlag = true;
        }

        watch(()=>props.editImeiFlag,(n,o)=>{
              //console.log('xxxxxxxxxxxxx 编辑'+n+","+deviceData.imeiTableType);

            if(deviceData.editSign==2){
                //新增时
                deviceData.editImeiForm = {};
                deviceData.editImeiForm.isEdit = false;
                deviceData.yearVal = 30;
                deviceData.editImeiForm.expireDay = 30;
                if(deviceData.imeiTableType>0){
                    deviceData.editImeiForm.type = deviceData.imeiTableType;                   
                }
            }else{
                //修改时
                let obj:any = {};
                Object.assign(obj, props.editImeiFormData);
                deviceData.editImeiForm = obj;

                deviceData.editImeiForm.isEdit = true;
                if(deviceData.editSign==1){
                    deviceData.editImeiForm.imeis = obj.imeis;
                    deviceData.editImeiForm.expireDay = 30;
                    delete deviceData.editImeiForm.cusName;
                }else{
                    deviceData.editImeiForm.imeis = obj.imei;
                }
            }
            
            deviceData.addImeiFlag = n;
        })
        
        watch(()=>deviceData.addImeiFlag,(n,o)=>{
           console.log('addImeiFlag 编辑'+n);
           if(!n){
               deviceData.editSign = 0;
           }
            
            emit('editImeiClosed',n);
        })
        const statusChange = (e:string)=>{  
            // deviceData.statusVal = e;
        }


        const submitUpload = () => {
            console.log('submitUpload');

            
        imeiRef.value.validate(async (valid: any) => {
         if (valid) {
          uploadRef.value!.submit();
            // deviceData.uploadLoading = ElLoading.service({
            //     lock: true,
            //     text: '导入文件解析中',
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })
        } else {
          return false
        }
      })

            
        }


        const submitUploadToTxt = () => {
             console.log('submitUploadToTxt');
            // deviceData.uploadLoading = ElLoading.service({
            //     lock: true,
            //     text: '导入文件解析中',
            //     background: 'rgba(0, 0, 0, 0.7)',
            // })

            imeiRef.value.validate(async (valid: any) => {
            if (valid) {
                uploadTXTRef.value!.submit();
            } else {
                return false
            }
            })
        }

        const handleError = (res: any) => { 
            console.log('handleError');
        }
        
        // const handleUpload = (res: any) => { 
        //     console.log('handleUpload');
        // }


        const handleUpload = (rawFile: any) => {
            console.log('handleUpload: '+rawFile.type);

            let a = "";
            a.endsWith
  if (rawFile.name.endsWith('.xlsx') || rawFile.name.endsWith('.xls') || rawFile.name.endsWith('.txt')) {
    
    if (rawFile.size / 1024 / 1024 > 10) {
        ElMessage.error('上传文件不能大于10MB!')
        return false
    }
    deviceData.uploadLoading = ElLoading.service({
        lock: true,
        text: '导入文件解析中',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    return true
  } else {
    ElMessage.error('仅支持excel文件或TXT文件!')
    return false
  }

}
        
        const handleSuccess = (res:any) => {
            // console.log('resresres'+res);
            
            if(deviceData.importImeiFlag){
                uploadRef.value!.clearFiles();
            }

            if(deviceData.importImeiToTXTFlag){
                uploadTXTRef.value!.clearFiles();
            }
            deviceData.importImeiFlag = false;
            deviceData.importImeiToTXTFlag = false;
            
            if(res.code ==0){
                ElMessage.success("上传成功");
                emit('addImeiSuccuss')
            }else if(res.errMsg!=null){
                if(res.data){
                    ElMessage.success(res.errMsg+' ,有问题的IMEI: '+JSON.stringify(res.data));
                }else{
                    ElMessage.success(res.errMsg);
                }
            }else{
                ElMessage.error("上传失败");
            }
            if(deviceData.uploadLoading!=null){
                deviceData.uploadLoading.close();
            }
        }


        const selectImei = ()=>{
            console.log("xxx");
             emit('searchData',deviceData.searchDataAny);
        }
        const addImeiFun = ()=>{
            // deviceData.editImeiForm = {};
            // deviceData.yearVal = 30;
            // deviceData.editImeiForm.expireDay = 30;
            // if(deviceData.imeiTableType>=0){
            //     deviceData.editImeiForm.type = deviceData.imeiTableType;
            // }
            // console.log("deviceData.editImeiForm.isEdit:"+deviceData.editImeiForm.isEdit);
            deviceData.editSign = 2;
            deviceData.addImeiFlag = true;
        }

        const excelImportImeiFun = ()=>{
            console.log("excelImportImeiFun");
            deviceData.importImeiForm = {};
            if(deviceData.imeiTableType>0){
                deviceData.importImeiForm.type = deviceData.imeiTableType;
            }
            
            deviceData.importImeiFlag = true;
        }

        
        const excelImportToTXTImeiFun = ()=>{
            console.log("excelImportToTXTImeiFun");
            deviceData.importImeiToTXTForm = {expireDay:30};
            if(deviceData.imeiTableType>0){
                deviceData.importImeiToTXTForm.type = deviceData.imeiTableType;
            }
            
            deviceData.importImeiToTXTFlag = true;
        }

        const createDevImeisFun = ()=>{
            console.log("createDevImeisFun");
            deviceData.createDevImeisForm = {expireDay:30};
            if(deviceData.imeiTableType>0){
                deviceData.createDevImeisForm.type = deviceData.imeiTableType; 
            }
            
             deviceData.createDevImeisFlag = true;
        }
        



        const deleteImei = ()=>{
            emit('deleteImeiAll');
        }
        //清除注册
        const removeRegistered = ()=>{
             emit('removeReg');
        }
        const closedFun = ()=>{
            deviceData.yearVal = 30;
            deviceData.editImeiForm.expireDay = 30;
            deviceData.importImeiToTXTForm.expireDay = 30;
            deviceData.createDevImeisForm.expireDay = 30;
        }
        const yearChange = (e:any)=>{
            
            deviceData.editImeiForm.expireDay = e;
            deviceData.importImeiToTXTForm.expireDay = e;
            deviceData.createDevImeisForm.expireDay = e;
        }


        const createDevSave = async()=>{

            imeiRef.value.validate(async (valid: any) => {
                if(valid){
                    const loading = ElLoading.service({
                        lock: true,
                        text: 'IMEI生成中',
                        background: 'rgba(0, 0, 0, 0.7)',
                    })
                    await createDevImeis(deviceData.createDevImeisForm).then((res:any)=>{
                        if(res.code == 0){
                            deviceData.createDevImeisFlag = false;
                            loading.close();
                            ElMessage.success("生成成功");
                            emit('addImeiSuccuss')
                        }else{
                            if(res.errMsg!=null){
                                ElMessage.success(res.errMsg);
                            }else{
                                ElMessage.success("生成失败");
                            }
                        }

                    });
                }else{
                    return false;
                }
            });
            
        }

        const editSave = async()=>{

            imeiRef.value.validate(async (valid: any) => {
                if(valid){
                    // let params = {
                // imeis:deviceData.imeiVal,
                // expireDay:deviceData.expireDay,
                // cusName:deviceData.cusName,
                // type:deviceData.imeiType
            // }
            
                    let fun = addImei;
                    let params;
                    if(deviceData.editImeiForm.isEdit){
                        fun = editImei;
                        params = {
                            imeis:deviceData.editImeiForm.imeis,
                            expireDay:deviceData.editImeiForm.expireDay,
                            cusName:deviceData.editImeiForm.cusName
                        };
                    }else{
                        params = deviceData.editImeiForm;
                    }

                    await fun(deviceData.editImeiForm).then((res:any)=>{
                        if(res.code == 0){
                            deviceData.addImeiFlag = false;
                            if(deviceData.editImeiForm.isEdit){
                                ElMessage.success("修改成功");
                            }else{
                                ElMessage.success("添加成功");
                            }
                            
                            emit('addImeiSuccuss')
                        }else{
                            if(res.errMsg!=null){
                                if(res.data){
                                    ElMessage.success(res.errMsg+' ,有问题的IMEI: '+JSON.stringify(res.data));
                                }else{
                                    ElMessage.success(res.errMsg);
                                }
                                
                            }else{
                                ElMessage.success("添加失败");
                            }
                        }

                    });
                }else{
                    return false;
                }
            });
            
        }

       const masterFileMax = async(files:any, fileList:any)=> {
            console.log(files, fileList);
            ElMessage.warning(`最多上传 ${1} 个文件。`);
        }

        return {
            ...data,
            statusChange,
            selectImei,
            addImeiFun,
            deleteImei,
            removeRegistered,
            closedFun,
            imeiRef,
            yearChange,
            editSave,
            excelImportImeiFun,
            excelImportToTXTImeiFun,
            uploadRef,
            uploadTXTRef,
            submitUpload,
            submitUploadToTxt,
            handleSuccess,
            masterFileMax,
            createDevImeisFun,
            createDevSave,
            editImeiFun,
            isEditAuthByCode,
            handleError,handleUpload

        }
    }
})
